import type { GetStaticPaths, GetStaticProps } from 'next'

import { getSiteConfiguration } from '@aether/services/sanity-service'
import { PageResponse, PageBuilderSection } from '@aether/models'
import { setTag } from '@sentry/nextjs'
import { DefaultPage } from '@aether/page'
import { getDefaultPageProps } from '../helpers/getDefaultPageProps'
import { REVALIDATE_PAGE } from '@aether/configs'
import { getActiveDiscounts } from '@aether/discounts/data-access'

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<
  PageResponse<PageBuilderSection[]>
> = async ({ params, locale, preview = false }) => {
  const { paths } = params

  if (typeof paths === 'string') {
    throw new Error('paths must be passed as an array. Received string')
  }

  const path = `/${paths ? paths.join('/') : ''}`
  setTag('page_path', path)
  setTag('page_locale', locale)

  /** Sanity driven data
   *  Distinguishing between specific types of pages (Journal, Utility etc.) and classic routes is inside
   * */
  const pageProps = await getDefaultPageProps({
    path,
    locale,
    isVip: false,
    preview,
  })

  if (!pageProps) {
    return {
      notFound: true,
      revalidate: REVALIDATE_PAGE,
    }
  }

  const siteConfiguration = await getSiteConfiguration(locale, preview)
  const discounts = await getActiveDiscounts()
  return {
    props: {
      ...pageProps,
      siteConfiguration,
      allDiscounts: discounts,
    },
    revalidate: REVALIDATE_PAGE,
  }
}

export default DefaultPage
